<template>
    <div class="colorlib-footer" style="text-align: center;">
        <p style="color: #FF474C;">Copyright &#169;
            {{ new Date().getFullYear() }}
            <a href="https://bryansilverio.com/" class="hover:underline"> Bryan Silverio</a>.
            All Rights Reserved.
        </p>
    </div>

</template>
<script src="./footerSection.js"></script>