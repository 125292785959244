<template>
    <div id="colorlib-main">
        <Profile />
        <About />
        <Skills />
        <Experience />
        <Projects />
        <Contact :socialNetworkType="'EMAIL'"/>
        <Footer />
    </div>
</template>
<script src="./indexPage.js"></script>